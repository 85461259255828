import { IPages } from "@/interfaces/customs/interface";
import {
  IBackgrounds,
  IDiagnostics,
  IFolio,
  IFile,
  IVitalSigns,
  IManagementPlans,
  IPhysicalExam,
  IProcedures,
  IRequestResponse,
  IReferenceCenter,
} from "@/interfaces/global/interfaces";

// Folios V.2
export type FoliosPartial =
  | "diagnostics"
  | "backgrounds"
  | "procedures"
  | "managementPlans"
  | "documents";

export type Folios = "folio" | "vitalSigns" | "physicalExam" | FoliosPartial;

export type FoliosRequest = Record<Folios, boolean>;
export type FoliosPages = Record<FoliosPartial, IPages>;

export interface FolioView {
  date: IFolio["current_schedule"]["session_date"];
  referenceCenter: IFolio["reference_center"]["name"];
  careCenter: IFolio["care_center"]["name"];
  doctor: IFolio["doctor"]["name"];
  specialty: IFolio["specialty"]["description"];
  eps: IFolio["patient"]["eps"]["name"];
  companionName: IFolio["name_companion_patient"];
  companionPhone: IFolio["phone_companion_patient"];
  companionRelationship: IFolio["relationship_companion_patient"];
  currentIllness: IFolio["current_illness"];
  reasonForConsultation: IFolio["reason_for_consultation"];
  analysis: IDiagnostics["description"];
  status: IFolio["current_schedule"]["status"];
}

export interface VitalSignsView {
  bloodPressure: IVitalSigns["average_blood_pressure"];
  heartRate: IVitalSigns["heart_rate"];
  breathingFrequency: IVitalSigns["breathing_frequency"];
  oxygenSaturation: IVitalSigns["oxygen_saturation"];
  temperature: IVitalSigns["temperature"];
  weight: IVitalSigns["weight"];
  height: IVitalSigns["height"];
  imc: IVitalSigns["imc"];
  imcDescription: IVitalSigns["weight_level"];
}

export interface DiagnosticsList {
  code: IDiagnostics["diagnostic"]["code"];
  description: IDiagnostics["diagnostic"]["description"];
  type: string; // change
}

export interface BackgroundsList {
  type: IBackgrounds["background"]["name"];
  description: IBackgrounds["description"];
}

export interface DocumentsList {
  center: IReferenceCenter["name"];
  date: IFile["date"];
  description: IFile["description"];
  url: IFile["url"];
  type: IFile["mime_type"];
  name: IFile["name"];
}

export interface ProceduresList {
  procedure: IProcedures["procedure"]["description"];
  presentationType: IProcedures["presentation_type"];
  usageFrequency: IProcedures["usage_frequency"];
  amount: IProcedures["ammount"];
  treatmentDuration: IProcedures["treatment_duration"];
  observation: IProcedures["observation"];
  warningSigns: IProcedures["warning_signs"];
}

export interface ManagementPlansList {
  medicine: IManagementPlans["medicine"]["description"];
  presentationType: IManagementPlans["presentation_type"];
  dosage: IManagementPlans["dosage_form"];
  administrationRoute: IManagementPlans["route_administration"];
  administrationFrequency: IManagementPlans["administration_frequency"];
  treatmentDuration: IManagementPlans["treatment_duration"];
  amount: IManagementPlans["amount"];
  posology: IManagementPlans["posology"];
  warningSigns: IManagementPlans["warning_signs"];
}

export interface FoliosData {
  procedures: IProcedures[];
  managementPlans: IManagementPlans[];

  // Update endpoint
  folio: IFolio | null;
  vitalSigns: IVitalSigns | null;
  physicalExam: IPhysicalExam[];
  diagnostics: IDiagnostics[];
  backgrounds: IBackgrounds[];
  documents: IFile[];
}

export interface FoliosState {
  // Folios V.2
  request: FoliosRequest;
  data: FoliosData;
  pages: FoliosPages;
  response: IRequestResponse;
}

function state(): FoliosState {
  return {
    // Folios V.2
    request: {
      folio: false,
      vitalSigns: false,
      physicalExam: false,
      diagnostics: false,
      backgrounds: false,
      procedures: false,
      managementPlans: false,
      documents: false,
    },
    data: {
      folio: null,
      vitalSigns: null,
      physicalExam: [],
      diagnostics: [],
      backgrounds: [],
      procedures: [],
      managementPlans: [],
      documents: [],
    },
    pages: {
      diagnostics: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      backgrounds: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      procedures: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      managementPlans: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
      documents: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
