import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/medicamentos",
    name: "MedicinesList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Medicines List" */ "@/views/medicines/MedicinesList.vue"
      ),
  },
  {
    path: "/medicamentos/crear-medicamentos",
    name: "MedicinesCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Medicines Create" */ "@/views/medicines/sub-pages/medicines-create/MedicinesCreate.vue"
      ),
  },
  {
    path: "/medicamentos/editar-medicamentos/:id(\\d+)",
    name: "MedicinesEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Medicines Edit" */ "@/views/medicines/sub-pages/medicines-edit/MedicinesEdit.vue"
      ),
  },
];
