import {
    ICenters,
    TCentersRequests,
    TCentersValue,
    TValue,
  } from "@/store/custom-components/state";
  import { StateInterface } from "@/store/index";
  import { computed, toRef } from "vue";
  import { useStore } from "vuex";
  
  export const useCustomComponentsStore = () => {
    const store = useStore<StateInterface>();
  
    return {
      // State
      centers: {
        data: toRef(store.state.customComponents.data.settings, "centers"),
        values: toRef(store.state.customComponents.values.settings, "centers"),
        requests: toRef(
          store.state.customComponents.requests.settings,
          "centers"
        ),
      },
      // centers: toRef(store.state.customComponents.data.settings, "centers"),
      settings: toRef(store.state.customComponents.data, "settings"),
      requests: toRef(store.state.customComponents, "requests"),
      values: toRef(store.state.customComponents, "values"),
      data: toRef(store.state.customComponents, "data"),
      response: toRef(store.state.customComponents, "response"),
  
      // Getters
  
      // Actions
      loadCenters: () => store.dispatch("customComponents/loadCenters"),
      loadCareCenters: () => store.dispatch("customComponents/loadCareCenters"),
  
      //Mutations
      setCenters: (data: { center: TCentersRequests; data?: ICenters[] }) =>
        store.commit("customComponents/setCenters", data),
      setCentersValue: (data: { center: TCentersValue; value?: TValue }) =>
        store.commit("customComponents/setCentersValue", data),
    };
  };
  