import {
  isFirtsLoginGuardTokenParam,
  changePasswordGuardTokenParam,
} from "@/router/auth-guard";

export default {
  name: "auth",
  component: () =>
    import(
      /* webpackChunkName: "Login View" */ "@/views/loginview/LoginView.vue"
    ),
  children: [
    {
      path: "",
      name: "login",
      // component: () => import(/* webpackChunkName: "Login" */ '@/components/login/MainLogin.vue'),
      component: () => import("@/components/login/v2/LoginV2.vue"),
    },
    {
      path: "/asignar-contrasenia",
      beforeEnter: [isFirtsLoginGuardTokenParam],
      name: "AssingFirtsPassword",
      component: () =>
        // import(
        //   /* webpackChunkName: "Change Password Email" */ "@/components/assign-firts-password/AssingFirtsPassword.vue"
        // ),
        import("@/components/change-password-v2/ChangePasswordV2.vue"),
    },
    {
      path: "/cambiar-contrasenia",
      beforeEnter: [changePasswordGuardTokenParam],
      name: "changePassword",
      component: () =>
        import(
          /* webpackChunkName: "Change Password" */ "@/components/change-password/ChangePassword.vue"
        ),
    },
    {
      path: "/cambiar-contrasenia-mail",
      name: "changepasswordEmail",
      component: () =>
        import(
          "@/components/login-recovery-password/LoginRecoveryPassword.vue"
        ),
    },
  ],
};
